import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import ReactDOM from 'react-dom';
import TableDataByAccount from './TableDataByAccount'
import TableDataByAccountCenterAdj from './TableDataByAccountCenterAdj'
import TableDataByAccountCorpAdj from './TableDataByAccountCorpAdj'
import 'url-search-params-polyfill';
import config from '../../config';
import { CreateGetRequest } from '../Shared/ApiHelper';

export class TransactionInquiryByAccount extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        let logininfo = JSON.parse(sessionStorage.getItem("logininfo"));
        const qs = new URLSearchParams(window.location.search)
        const account = qs.get('account')
        
        this.state = {
            index: logininfo.index,
            AccountNumber: account,
            Code: logininfo.code,
            validationError: "",
            clicked: false,
            loading: false,
            returnResults: [],
            returnResultsCenterAdj: [],
            returnResultsCorpAdj: []
        };

        this.getAllAccountData = this.getAllAccountData.bind(this);
        this.getAccountPaymentDetail = this.getAccountPaymentDetail.bind(this);
        this.getAccountCenterAdjData = this.getAccountCenterAdjData.bind(this);
        this.getAccountCorpAdjData = this.getAccountCorpAdjData.bind(this);

        if (this.state.AccountNumber && this.state.Code) {
            this.getAllAccountData();
        }
    }

    componentDidMount() {
         if (this.state.AccountNumber && this.state.Code) {
             this.getAllAccountData();
         }
    }

    getAllAccountData() {
        
        this.setState({
            clicked: false,
            loading: true
        });

        this.getAccountPaymentDetail();
        this.getAccountCenterAdjData();
        this.getAccountCorpAdjData();
    }
    getAccountPaymentDetail() {
        const uri = `${config.getApiRoot()}/transactions/online/${this.state.index}/codes/${this.state.Code}/accounts/${this.state.AccountNumber}`;
        
        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ returnResults: response.body.transactions });
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    getAccountCenterAdjData() {
        const uri = `${config.getApiRoot()}/adjustments/centers/${this.state.index}/codes/${this.state.Code}/accounts/${this.state.AccountNumber}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ returnResultsCenterAdj: response.body.centerAdjTransactions });
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    getAccountCorpAdjData() {
        const uri = `${config.getApiRoot()}/adjustments/corporate/${this.state.index}/codes/${this.state.Code}/accounts/${this.state.AccountNumber}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ returnResultsCorpAdj: response.body.corpAdjTransactions });
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSubmit = (event, errors, values) => {
        
        this.setState({
            clicked: false,
            loading: true,
        });
        this.setState({ errors, values });

        if (errors.length > 0) return;

        this.getAllAccountData();
    }

    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Transaction Inquiry by Account Number</span>
                </div>
                <AvForm id="search_by_account" onSubmit={this.handleSubmit}>
                    <AvField
                        id="AccountNumber"
                        name="AccountNumber"
                        label="Enter Account Number"
                        type="text"
                        value={this.state.AccountNumber || ""}
                        onChange={this.handleChange}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Please enter an account number."
                            }
                        }}
                    />
                    <Button color="primary">GO</Button>
                </AvForm>

                {this.state.loading ? <div id="divloading">loading...</div> : null}
                {this.state.clicked ? <TableDataByAccount data={this.state.returnResults} /> : null}
                {this.state.clicked ? <TableDataByAccountCenterAdj data={this.state.returnResultsCenterAdj} /> : null}
                {this.state.clicked ? <TableDataByAccountCorpAdj data={this.state.returnResultsCorpAdj} /> : null}
            </div>
        );
    }
}