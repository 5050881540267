import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ErrorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });   
    }

    render() {      

        return (
            <div>                
                <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle} className={this.props.className} backdrop={false} >
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody>
                        <h4>{this.props.modalTitleText}</h4>
                        {this.props.modalBodyText}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>OK</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ErrorModal;