import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './css/lastname.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class ActiveFormatter extends React.Component {
    constructor(props) {
        super(props);
       this.state = {
           href: `/BillPay/TransactionInquiryByAccount?code=${this.props.code}&account=${this.props.account}`
        };
    }
    render() {
        return (
            <a href={ this.state.href } onmouseover = "this.style.color = 'blue'" onmouseout = "this.style.color = '#669966'" > { this.props.account }</a >
    );
}
}

function activeFormatter(cell, row) {
    return (
    <ActiveFormatter account={cell} />
);
}

export class TableDataByLastName extends Component {

    
    render() {
        return (
            <div>
                <BootstrapTable striped data={this.props.data} className='lastname' pagination>
                    <TableHeaderColumn dataField='account' dataFormat={activeFormatter} isKey={true}>Account</TableHeaderColumn>
                    <TableHeaderColumn dataField='firstName' thStyle={{ whiteSpace: 'normal' }}>First Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='lastName' >Last Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='address1' >Address 1</TableHeaderColumn>
                    <TableHeaderColumn dataField='address2' >Address 2</TableHeaderColumn>
                    <TableHeaderColumn dataField='city' >City</TableHeaderColumn>
                    <TableHeaderColumn dataField='state' >State</TableHeaderColumn>
                    <TableHeaderColumn dataField='zip' >Zip</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default TableDataByLastName;