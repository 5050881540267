import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import './BillPay/billpay.css';
import hdrlogo from './BillPay/images/fp-top1.jpg';
import hdrlogoBkg from './BillPay/images/fp-top1bg.jpg';

export class Support extends React.Component {

    componentDidMount() {
    }


    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="container" >
                    <div className="row justify-content-md-left">
                        <div className="col green">
                            <span>Registration</span>
                        </div>
                        <div className="container" >
                            <p>For information about or to gain access to the ACE Client
                            Services Interface, email your request to <a href="mailto:ACECSI@acecashexpress.com">ACECSI@acecashexpress.com</a>. Make
                            sure to include your company name along with the name, email address and
                            contact number for each person who requires access. You will receive a
                            confirmation email of your request. Your login will be setup within 1
                            business day.
                            </p>
                        </div>

                        <div className="col green">
                            <span>
                            Client Support</span>
                        </div>
                        <div className="container" >
                             <font color="#000000"><strong>Login Assistance</strong></font>
                            <p>Are you having problems logging in? <a href="#trouble">Click Here</a></p>
                            <p>
                            <strong>
                            <font color="#000000">Make a suggestion or feature request</font></strong><br/>
                            <a href="mailto:ACECSI@acecashexpress.com">Email us</a>  if you have suggestions
                            for improvements to our Client Services Interface, such as additional
                            features or ways to make the interface more helpful.  
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col green">
                            <span>Payment Research Tools</span>
                        </div>
                        <div className="container" >
                            <p>
                            <strong><font color="#000000">Transaction/Payment Inquiry</font></strong><br/>
                            Conduct a search of payments based on various inquiry criteria.<br/>
                            <strong><font color="#000000">Account Number Search</font></strong><br/>
                            Look up all payments for a specific account number. <br/>
                            <font color="#000000"><strong>Misapplied Payments</strong></font><br/>
                            Use an account number to search ACE&#39;s system to determine if the
                            payment may have been sent to another client.<br/>
                            <strong><font color="#000000">by Authentication Number</font></strong><br/>
                            Each customer&#39;s receipt contains a unique authentication number
                            that will allow you to research that transaction.<br/>
                            <strong><font color="#000000">by Date</font></strong><br/>
                            This inquiry allows you to view all transaction activity for a previous
                            date.<br/>
                            <strong><font color="#000000">by Center</font></strong><br/>
                            View all transactions performed at a specific ACE center.<br/>
                            <strong><font color="#000000">Downloads</font></strong><br/>
                            Download data from inquiries such as a Center Listing or ACH Data into
                            an Excel spreadsheet.
                            </p>

                        </div>
                        <div className="col col-lg green">
                                                                                <span>	Having Trouble logging in?</span>
                        </div>
                    <div className="container" >
                        <p>
                        <font color="#000000"><strong>Did you send your information to complete the registration process?</strong></font><br/>
                        Please check the login name and password that you received in your
                        confirmation email during the acecsi.com registration process. If
                        the information you entered is correct, click the browser reload
                        or refresh
                        button on the top of the page and retry. <font color="#333333"><strong>You
                        must complete the registration request
                        and receive the confirmation for access to ACECSI.com before you can
                        log in.  </strong> </font>
                        </p>
                            <p>
                            <font color="#000000"><strong>Did you mistype your user name and/or password?</strong></font><br/>
                            Click on your browser's reload or refresh button. Please note that
                                user names and passwords are case-sensitive: "johndoe" and "Johndoe" are
                                not considered the same user name. Make sure that your Caps Lock button
                                is disabled. If your browser doesn't reload, try using your browser's "Back" button
                                and then selecting the link you were trying to access again.          
                            </p>
                            <p><font color="#000000"><strong>Did you forget your username/password?</strong></font></p>
                            <p>Send an email to <a href="mailto:ACECSI@acecashexpress.com">ACECSI@acecashexpress.com</a></p>
    </div>
                    </div>
                    <div className="row justify-content-md-left">
                    <div className="col green">
                            <span>	Privacy Notice</span>
                    </div>
                    <div className="container" >
                            <p>
                            Ace Cash Express knows that you care how information is provided and
                                available to you, and we appreciate your trust that we will do so carefully
                                and sensibly. This notice describes our privacy policy. By visiting ACECSI.com,
                            you are accepting the practices described in this <a href="https://www.acecashexpress.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy
                            Notice</a>.
                    </p>
                    </div>

                    </div>
                </div>

            </div>
        );
    }
}