import React from 'react';
import { Alert } from 'reactstrap';

class ErrorAlert extends React.Component {
    render() {      

        return (
            <div>
                <Alert color="danger" fade={false}>
                    {this.props.messageText}
                </Alert>
            </div>
        );
    }
}

export default ErrorAlert;