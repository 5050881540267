import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class ActiveFormatter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            href: "/BillPay/TodaysActivity?code=" + this.props.code
        };
    }
    render() {
        return (
            <p>
               <strong>{this.props.message}</strong>
            </p>
        );
    }
}

function activeFormatter(cell, row) {
    return (
        <ActiveFormatter message={cell} />
    );
}

export class TableDataMessage extends Component {
    render() {
        return (
            <div>
                <BootstrapTable striped data={this.props.data} bordered={false} tableContainerClass='tablec table-borderless messages' >
                    <TableHeaderColumn dataField='message' dataFormat={activeFormatter} isKey={true} ></TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default TableDataMessage;