import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './css/misacct.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

export class TableDataByMisAccount extends Component {
   
    render() {
        return (
            <div>
                <BootstrapTable striped data={this.props.data} className='misacct' pagination>
                    <TableHeaderColumn dataField='account' isKey={true}>Account #</TableHeaderColumn>
                    <TableHeaderColumn dataField='code'>Code</TableHeaderColumn>
                    <TableHeaderColumn dataField='amount'>Amount</TableHeaderColumn>
                    <TableHeaderColumn dataField='center'>Center</TableHeaderColumn>
                    <TableHeaderColumn dataField='transDT'>Trans Date</TableHeaderColumn>
                </BootstrapTable>

         
            </div>
        );
    }
}

export default TableDataByMisAccount;