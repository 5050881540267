import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import ReactDOM from 'react-dom';
import Modal from "react-responsive-modal";
import TableDataTodaysActivity from './TableDataTodaysActivity'
import config from '../../config';
import ErrorModal from '../Shared/ErrorModal';
import 'url-search-params-polyfill';
import { CreateGetRequest } from '../Shared/ApiHelper';
import { Today } from '../Shared/DateHelper';

export class TodaysActivity extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        this.state = {
            open: false, Header: "",
            clicked: false,
            validationError: "",
            loading: false,
            code: false,
            returnResults:[]
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClick2 = this.handleClick2.bind(this);
    }
    
    handleClick() {
        this.setState({
            clicked: true
        });
    }

    handleClick2() {
        this.props.history.push('BillPay/TodaysActivityByCenter');
    }
    onOpenModal = (name) => {
        this.setState({ Header: name });
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        var search = new URLSearchParams(window.location.search);
        let code = search.get("code");
        document.getElementById("pgtitle").innerHTML = "Today's Activity for " + code;
        this.setState({
            code: code
        });
        if (code) {
            this.getActivityData(code);
        }
    }

    getActivityData(code) {
        
        this.setState({
            clicked: false,
            loading: true
        });
        
        const uri = `${config.getApiRoot()}/activities/today/${code}?date=${Today()}`;
        
        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                
                if (response.status === 200 && response.body.paymentActivities) {
                    this.setState({ returnResults: response.body.paymentActivities });
                }
                
                this.setState({
                    clicked: true,
                    loading: false
                });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
            });
    }
    render() {
        const { open } = this.state;

        return (
            <div class="lowest">
            <div id="error-modal-container"></div>

                <a href="/BillPay">
                    <div class="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div class="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>

                <div class="clear"></div>
                <div class="padBackBtn">
                    <button class="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span id="pgtitle" class="pgtitle">Today's Activity</span>
                </div>

                {this.state.loading ? <div id="divloading">loading...</div> : null}

                {this.state.clicked ? <TableDataTodaysActivity data={this.state.returnResults} code={this.state.code}/> : null}


                <Modal open={open} onClose={this.onCloseModal} center>
                    <h2>
                        {this.state.Header} </h2>
                    <div class="container">
                        <div class="form-group row">
                            <label for="first_name" class="col-xs-3 col-form-label mr-2">First Name</label>
                            <div class="col-xs-9">
                                <input type="text" class="form-control" value={this.state.code} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}