import React from 'react';

export default class AceCsiBase extends React.Component {
    constructor(props) {
        super(props);
        this.CheckLoggedInUser = this.CheckLoggedInUser.bind(this);
        this.CheckLoggedInUser();
    }
    
    CheckLoggedInUser() {
        //check session token
        const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));
        //console.log(token);
        if(token === null || token.UserName === '')
        {
            //redirect to login page
            let path = `/Login`;
            this.props.history.push(path);
        }        
    }

    render() {      

        return (
            <React.Fragment />
        );
    }
}