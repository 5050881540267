import React, { Component } from "react";
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse } from "reactstrap";
import { UserRoleBasedMenu } from "./Shared/UserRoleBasedMenu";
import { LoginLogoutMenu } from "./Shared/LoginLogoutMenu";
import "./NavMenu.css";

export class NavMenu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar color="dark" dark expand="md">
        <NavbarBrand href={`${process.env.PUBLIC_URL}/`}>ACECSI - Bill Pay</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar left="true">
            <UserRoleBasedMenu />
          </Nav>

          <Nav className="ml-auto" right="true">
            <LoginLogoutMenu />
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
