import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM, GetCachedCodeList } from '../AceCsiAccessHelper';
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import ReactDOM from 'react-dom';
import TableDataByLastName from './TableDataByLastName'
import config from '../../config';
import { CreateGetRequest } from '../Shared/ApiHelper';

export class TransactionInquiryByLastName extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        let logininfo = JSON.parse(sessionStorage.getItem("logininfo"));
        var codes = GetCachedCodeList();

        this.state = {
            validationError: "",
            LastName: "",
            clicked: false,
            loading: false,
            codeList: codes,
            code: logininfo.code,
            returnResults:[],
            index: logininfo.index
        };

        this.handleClickCom = this.handleClickCom.bind(this);
    }

    handleClickCom() {
        this.setState({
            clicked: true
        });
    }
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSubmit = (event, errors, values) => {
        
        this.setState({
            clicked: false,
            loading: true
        });
        this.setState({ errors, values });

        if (errors.length > 0) return;

       const uri = `${config.getApiRoot()}/transactions/${this.state.index}/codes/${this.state.code}/customers/${this.state.LastName}`; 
       
       fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => { 
                if (response.status === 200 && response.body.transactions) {
                    this.setState({ returnResults: response.body.transactions})
                }
                
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Transaction Inquiry by Last Name</span>
                </div>
                <AvForm id="search_by_lastname" onSubmit={this.handleSubmit}>
                    <AvField
                        id="LastName"
                        name="LastName"
                        label="Enter Last Name"
                        type="text"
                        onChange={this.handleChange}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Please enter a last name."
                            }
                        }}
                    />
                    <Button color="primary">GO</Button>
                </AvForm>

                {this.state.loading ? <div id="divloading">loading...</div> : null}
    
                {this.state.clicked ? <TableDataByLastName data={this.state.returnResults} /> : null}
            </div>
        );
    }
}