import React from "react";
import { NavLink, NavItem } from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";

export class UserRoleBasedMenu extends React.Component {
  renderSwitch = () => {
    const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));

    if (token === null) return <React.Fragment />;
    else
      switch (token.UserRole) {
        case "ADM":
          return (
            <React.Fragment>
              <NavItem className="left-menu">
                <LinkContainer to="/AdminIDE/LogView">
                  <NavLink activeClassName="navbar-active-link">Log View</NavLink>
                </LinkContainer>
              </NavItem>
            </React.Fragment>
          );
        case "ATO":
          return (
            <React.Fragment>
              <NavItem className="left-menu">
                <LinkContainer to="/AutoLoan/PictureLookup">
                  <NavLink>Picture Lookup</NavLink>
                </LinkContainer>
              </NavItem>
            </React.Fragment>
          );
        case "ODM":
          return (
            <React.Fragment>
              <NavItem className="left-menu">
                <LinkContainer to="/DMPortal/PayrollAdjustments">
                  <NavLink activeClassName="navbar-active-link">Payroll Adjustments</NavLink>
                </LinkContainer>
              </NavItem>
              <NavItem className="left-menu">
                <LinkContainer to="/DMPortal/EDDList">
                  <NavLink>Enhanced Due Diligence</NavLink>
                </LinkContainer>
              </NavItem>
            </React.Fragment>
          );
        case "DBT":
          return (
            <React.Fragment>
              <NavItem className="left-menu">
                <LinkContainer to="/DebtRpts/DocumentList">
                  <NavLink>Document List</NavLink>
                </LinkContainer>
              </NavItem>
            </React.Fragment>
          );
        default:
          return <React.Fragment />;
      }
  };

  render() {
    return <React.Fragment>{this.renderSwitch()}</React.Fragment>;
  }
}
