import React from 'react';
import { Button } from 'reactstrap';

export class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.logoutMe = this.logoutMe.bind(this);
        this.logoutCancel = this.logoutCancel.bind(this);
    }
    
    logoutMe() {
        //clear session
        sessionStorage.clear();
        //redirect to login page
        let path = '/Login';
        this.props.history.push(path);
    }

    logoutCancel() {
        this.props.history.goBack();
    }

    render() {      

        return (
            <div>
                <h4>Are you sure you want to Logout?</h4>
                <Button
                    color="primary"
                    type="submit"
                    onClick={this.logoutMe}
                >Yes, Logout</Button>
                <Button
                    color="secondary"
                    className="m-2"
                    type="submit"
                    onClick={this.logoutCancel}
                >Cancel</Button>           
            </div>
        );
    }
}