import React, { Component } from "react";
import ReactDOM from "react-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import ErrorModal from "../Shared/ErrorModal";
import ErrorAlert from "../Shared/ErrorAlert";
import SuccessAlert from "../Shared/SuccessAlert";
import config from '../../config';
import { CreatePostRequest } from '../Shared/ApiHelper';

export class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            UserName: "",
            role: "NA"
        };
    }

    handleChange = event => {
            this.setState({
                [event.target.id]: event.target.value
            });
    };

    handleSubmit = (event, errors, values) => {
        event.preventDefault();

        this.setState({ errors, values });

        if (errors.length > 0) return;

        var data = {
            UserName: this.state.UserName,
            Role: this.state.role
        };

        const uri = `${config.getApiRoot()}/users/password/reset`;

        fetch(uri, CreatePostRequest(data))
            .then(res =>
                res.json().then(data => ({ status: res.status, body: data }))
            )
            .then(response => {
                if (response.status === 200) {
                    if (response.body.isSuccess === true) {
                        const successAlert = (
                            <SuccessAlert messageText="An Email has been sent to reset password." />
                        );
                        ReactDOM.render(
                            successAlert,
                            document.getElementById("resetpwd-response-message")
                        );
                        document.getElementById("resetpwd-form").reset(); //reset form
                    } else {
                        const errorAlert = (
                            <ErrorAlert messageText={response.body.message} />
                        );
                        ReactDOM.render(
                            errorAlert,
                            document.getElementById("resetpwd-response-message")
                        );
                    }
                } else {
                    const titleText = "Bad Request: " + response.status;
                    const bodyText =
                        response.body +
                        " This issue has been logged into database with Log ID:";
                    const errorModal = (
                        <ErrorModal modalTitleText={titleText} modalBodyText={bodyText} />
                    );
                    ReactDOM.render(
                        null,
                        document.getElementById("error-modal-container")
                    );
                    ReactDOM.render(
                        errorModal,
                        document.getElementById("error-modal-container")
                    );
                }
            })
            .catch(error => {
                console.error(error);

                const errorModal = (
                    <ErrorModal
                        modalTitleText="Server Error"
                        modalBodyText="This issue has been logged into database."
                    />
                );
                ReactDOM.render(null, document.getElementById("error-modal-container"));
                ReactDOM.render(
                    errorModal,
                    document.getElementById("error-modal-container")
                );
            });
    };

    render() {
        return (
            <div className="center">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div id="error-modal-container" />
                                <div id="resetpwd-response-message" />
                                <div className="panel panel-info">
                                    <div className="panel panel-default min-width-275">
                                        <div className="panel-heading rounded">
                                            <h3 className="panel-title p-2">Reset Password</h3>
                                        </div>
                                        <div className="panel-body rounded shadow p-3">
                                            <AvForm id="resetpwd-form" onSubmit={this.handleSubmit}>
                                                <AvField
                                                    id="UserName"
                                                    name="UserName"
                                                    label="User Name"
                                                    type="text"
                                                    className="w-100 p-3"
                                                    onChange={this.handleChange}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please enter User Name"
                                                        },
                                                        pattern: {
                                                            value: "^[A-Za-z0-9]+$",
                                                            errorMessage:
                                                                "User Name must be composed only with letter and numbers"
                                                        },
                                                        minLength: {
                                                            value: 1,
                                                            errorMessage:
                                                                "User Name must be between 1 and 20 characters"
                                                        },
                                                        maxLength: {
                                                            value: 20,
                                                            errorMessage:
                                                                "User Name must be between 1 and 20 characters"
                                                        }
                                                    }}
                                                />
                                                <Button color="primary">Submit</Button>
                                            </AvForm>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
