import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import './css/ptoday.css';
import logo from './images/fp-db.jpg';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import ReactDOM from 'react-dom';
import ErrorModal from '../Shared/ErrorModal';
import TableDataByPaymentsToday from './TableDataByPaymentsToday'
import TableDataMessage from './TableDataMessage'
import config from '../../config'
import { Today } from '../Shared/DateHelper';
import { CreateGetRequest } from '../Shared/ApiHelper';
import { CreateGetDownloadRequest, DownloadFile } from '../Shared/ApiHelper';

export class ClientServicesInterface extends AceCsiBase {
    constructor(props) {
        super(props);
        
        OnlyAccessToBPM(this.props.history);
        
        this.state = {
            code: "",
            index: "",
            loading: true,
            gloading: true,
            gclicked: false,
            codes:"",
            clicked: false
        };

        this.getTodaysActivity = this.getTodaysActivity.bind(this);
        this.getActiveCenters = this.getActiveCenters.bind(this);
        this.fetchLoginInfo = this.fetchLoginInfo.bind(this);
        this.getLoginInfo = this.getLoginInfo.bind(this);
        this.getGlobalMessages = this.getGlobalMessages.bind(this);
        this.loadPageData = this.loadPageData.bind(this);
    }
    componentDidMount() {
        this.getLoginInfo();
    }

    getLoginInfo() {
        let logjson = sessionStorage.getItem("logininfo");
        const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));
        let loginfo;
        
        if (logjson === undefined || logjson === null || logjson === 'undefined') {
            loginfo = this.fetchLoginInfo(token.UserName);
        } 
        else {
            loginfo = JSON.parse(logjson);
            this.setState({
                code: loginfo.code,
                index: loginfo.index,
            });
            
            this.loadPageData(loginfo.code, loginfo.index)
        }
        
        return loginfo;
    }

    loadPageData(code, index) {
        this.getTodaysActivity(code, index);
        this.getGlobalMessages(code, index);
    }

    getGlobalMessages(code, index) {

        const uri = `${config.getApiRoot()}/messages?code=${code}&index=${index}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200 && response.body.messages) { 
                    const messageList = this.createMessageList(response.body.messages)
                    this.setState({ gMessages: messageList });
                }

                this.setState({ gclicked: true, gloading: false });
            })
            .catch(error => {
                console.error(error);
                this.setState({ gclicked: true, gloading: false });
            });
    }

    fetchLoginInfo(userName) {
        const uri = `${config.getApiRoot()}/users/${userName}/roles`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) { 
                    sessionStorage.setItem('logininfo', JSON.stringify(response.body));
                    this.setState({
                        code: response.body.code,
                        index: response.body.index,
                    });
                    this.loadPageData(response.body.code,response.body.index);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    getTodaysActivity(code, index) {
        const today = Today();
        const uri = `${config.getApiRoot()}/payments/summary/${index}/codes/${code}?date=${today}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                
                if (response.status === 200 && response.body) { 
                    this.setState({ returnResults: response.body.payments })
                }

                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    getActiveCenters(e) {
        e.preventDefault();
        const uri = `${config.getApiRoot()}/downloads/centers`;

        fetch(uri, CreateGetDownloadRequest())
            .then(response => response.blob())
            .then(blob => { 
                
                if(blob && blob.size > 0) {
                    DownloadFile(blob, 'ActiveCenters.xlsx');
                }
                else {
                    alert('unable to download file')
                }

                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
            });
    }

    createMessageList(rawMessages) {
        if(rawMessages.length > 0) {
            var key = 0;
            
            const messageList = rawMessages.map(msg => {
                var obj = {};
                obj['key'] = key;    
                obj['message'] = msg
                key++;
                return obj;
            });
            
            return messageList;
        }
        else {
            return [];
        }
    }

    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="container" >
                    <div className="row justify-content-md-left">
                        <div className="col green">
                            <span>Today's Activity</span>
                        </div>
                        <div className="container" >
                            {this.state.loading ? <div id="divloading">loading...</div> : null}
                            {this.state.clicked ? <TableDataByPaymentsToday data={this.state.returnResults} /> : null}
                        </div>

                        <div className="col green">
                            <span>Transaction/Payment Inquiry</span>
                        </div>
                        <div className="container" >
                            <div className="row">
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" />
                                    <a href="/BillPay/TransactionInquiryByAuth"><strong>by Authentication Number</strong></a>
                                </div>
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" />
                                    <a href="/BillPay/TransactionInquiryByCenter"><strong>by Active Center List</strong></a>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" />
                                    <a href="/BillPay/TransactionInquiryByAccount"><strong>by Account Number</strong></a>
                                </div>
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" />
                                    <a href="/BillPay/TransactionInquiryByLastName"><strong>by Last Name</strong></a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" />
                                    <a href="/BillPay/TransactionInquiryByDate"><strong>by Date</strong></a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" />
                                    <a href="/BillPay/TransactionInquiryByMisAcct"><strong>by Misapplied Account #</strong></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col green">
                            <span>Downloads</span>
                        </div>
                        <div className="container" >
                            <div className="row">
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" /><strong>
                                        <a href="/#" onClick={(e) => this.getActiveCenters(e)}  >Active Center List</a></strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" /><strong><a href="/BillPay/CenterActivity">Center Activity</a></strong>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <img src={logo} alt="logo" width="16" height="18" /><strong><a href="/BillPay/AchData">ACH Data</a></strong>
                                </div>
                            </div>

                        </div>
                        <div className="col col-lg green">
                            <span>Messages</span>
                        </div>
                        <div className="container" >
                            {this.state.gloading ? <div id="divloading">loading...</div> : null}
                            {this.state.gclicked ? <TableDataMessage data={this.state.gMessages} /> : null}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}