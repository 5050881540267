import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';

export class TodaysActivityByAccount extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
    }

    render() {
        return (
            <div class="lowest">
                <a href="/BillPay">
                    <div class="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div class="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div class="clear"></div>

                <div class="padBackBtn">
                    <button class="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span class="pgtitle">  Today's Activity By Center #1701</span>
                </div>
                <div class="container">
                    <table role="table">
                        <thead>
                            <tr role="row">
                                <th role="columnheader">Date</th>
                                <th role="columnheader">Center</th>
                                <th role="columnheader">Person</th>
                                <th role="columnheader">Account</th>
                                <th role="columnheader">Amount</th>
                                <th role="columnheader">Fee</th>
                                <th role="columnheader">Phone</th>
                                <th role="columnheader">Paid by</th>
                                <th role="columnheader">Code</th>
                                <th role="columnheader">Trans Number</th>
                                <th role="columnheader">Auth Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr role="row">
                                <td role="cell">03/29/2019</td>
                                <td role="cell"><a href="/BillPay/TodaysActivityByCenter" onmouseover="this.style.color='blue'" onmouseout="this.style.color='#669966'">1701</a></td>
                                <td role="cell">GILBERTO MENDOZA</td>
                                <td role="cell"><a href="/BillPay/TransactionInquiry" title="Account Number History and Adjustments" onmouseover={"this.style.color='blue'" } onmouseout={"this.style.color='#669966' style='color: rgb(102, 153, 102);'"}>121803765886</a></td>
                                <td role="cell">136.54</td>
                                <td role="cell">3.00</td>
                                <td role="cell"> </td>
                                <td role="cell">$</td>
                                <td role="cell">SUE</td>
                                <td role="cell">1010004</td>
                                <td role="cell"><a href="AuthCheck.cfm?ID=08816170101013630786&amp;Amount=136.54&amp;Center=1701" title="Account Number History and Adjustments" onmouseover={"this.style.color='blue'"} onmouseout={"this.style.color='#669966' style = 'color: rgb(102, 153, 102);'"}>121803765886</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}