export default {

    userProfileTokenCredentials :
    {
        userId: "4EA458DE99FC43338A9A99FC579BE4F2",
        callerId: "8927BCCF27AB4AE896450327C6C87527",
        password: "5FD136906797491FA2DC4D42A651CEEC"
    },

    getApiRoot() {
        let apiRoot = new Map();
        // apiRoot.set('stage', 'http://localhost:59099/bp'); //Run locally
        apiRoot.set('dev', '');
        apiRoot.set('stage', 'https://apply.stage.acecashexpress.com/billpay.api/bp');
        apiRoot.set('prod', 'https://apply.acecashexpress.com/billpay.api/bp');
        return apiRoot.get(process.env.REACT_APP_ENV);
    },
}