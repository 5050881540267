import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './css/authcenteradj.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

export class TableDataByAuthCenterAdj extends Component {
    render() {
        return (
            <div>
                <p className="Table-header">Center Adjustment Detail</p>
                <BootstrapTable striped data={this.props.data} className='authcenteradj' pagination>
            <TableHeaderColumn dataField='account' isKey= {true}  >Account #</TableHeaderColumn>
            <TableHeaderColumn dataField='code'>Code</TableHeaderColumn>
            <TableHeaderColumn dataField='origAmt'>Orig</TableHeaderColumn>
            <TableHeaderColumn dataField='center'>Center</TableHeaderColumn>
            <TableHeaderColumn dataField='origDate'>Orig Date</TableHeaderColumn>
            <TableHeaderColumn dataField='adjustDT'>Adjust Date</TableHeaderColumn>
            <TableHeaderColumn dataField='newAmt'>New Amt</TableHeaderColumn>
            <TableHeaderColumn dataField='delta'>Delta</TableHeaderColumn>
            <TableHeaderColumn dataField='reason'>Reason</TableHeaderColumn>
            </BootstrapTable>
            </div>
        );
    }
}

export default TableDataByAuthCenterAdj;