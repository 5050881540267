import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';

//Login
import { Login } from './components/Login';
import { Logout } from './components/Logout';
import { ResetPassword } from './components/Account/ResetPassword';
import { ChangePassword } from './components/Account/ChangePassword';
import { AccessDenied } from './components/AccessDenied';

//BillPay
import { ClientServicesInterface } from './components/BillPay/ClientServicesInterface';
import { TodaysActivity } from './components/BillPay/TodaysActivity';
import { TodaysActivityByCenter } from './components/BillPay/TodaysActivityByCenter';
import { TodaysActivityByAccount } from './components/BillPay/TodaysActivityByAccount';
import { TransactionInquiryByCenter } from './components/BillPay/TransactionInquiryByCenter';
import { TransactionInquiryByAccount } from './components/BillPay/TransactionInquiryByAccount';
import { TransactionInquiryByAuth } from './components/BillPay/TransactionInquiryByAuth';
import { TransactionInquiryByDate } from './components/BillPay/TransactionInquiryByDate';
import { TransactionInquiryByLastName } from './components/BillPay/TransactionInquiryByLastName';
import { TransactionInquiryByMisAcct } from './components/BillPay/TransactionInquiryByMisAcct';
import { Support } from './components/Support';
import { TableDataByCenter } from './components/BillPay/TableDataByCenter';
import { TableDataByAccount } from './components/BillPay/TableDataByAccount';
import { TableDataByMisAccount } from './components/BillPay/TableDataByMisAccount';
import { TableDataByLastName } from './components/BillPay/TableDataByLastName';
import { TableDataTodaysActivity } from './components/BillPay/TableDataTodaysActivity';
import { TableDataByAuthNum } from './components/BillPay/TableDataByAuthNum';
import { CenterActivity } from './components/BillPay/CenterActivity';
import { ACHData } from './components/BillPay/ACHData';
import { TableDataMessage } from './components/BillPay/TableDataMessage';

export default class App extends Component {
    displayName = App.name;

    render() {
        return (
            <React.Fragment>

                <Layout>
                    <Route exact path="/" component={Login} />

                    <Route path='/Login' component={Login} />
                    <Route path='/Logout' component={Logout} />
                    <Route path='/Account/ResetPassword' component={ResetPassword} />
                    <Route path='/Account/ChangePassword' component={ChangePassword} />
                    <Route path='/AccessDenied' component={AccessDenied} />

                    <Route path='/ClientServicesInterface' component={ClientServicesInterface} />
                    <Route path='/TodaysActivity' component={TodaysActivity} />
                    <Route path='/TodaysActivityByCenter' component={TodaysActivityByCenter} />
                    <Route path='/TodaysActivityByAccount' component={TodaysActivityByAccount} />
                    <Route path='/TransactionInquiryByAuth' component={TransactionInquiryByAuth} />
                    <Route path='/TransactionInquiryByAccount' component={TransactionInquiryByAccount} />
                    <Route path='/TransactionInquiryByCenter' component={TransactionInquiryByCenter} />
                    <Route path='/TransactionInquiryByDate' component={TransactionInquiryByDate} />
                    <Route path='/TransactionInquiryByLastName' component={TransactionInquiryByLastName} />
                    <Route path='/TransactionInquiryByMisAcct' component={TransactionInquiryByMisAcct} />
                    <Route path='/TableDataByCenter' component={TableDataByCenter} />
                    <Route path='/TableDataByAccount' component={TableDataByAccount} />
                    <Route path='/TableDataByMisAccount' component={TableDataByMisAccount} />
                    <Route path='/TableDataByLastName' component={TableDataByLastName} />
                    <Route path='/TableDataTodaysActivity' component={TableDataTodaysActivity} />
                    <Route path='/TableDataByAuthNum' component={TableDataByAuthNum} />
                    <Route path='/CenterActivity' component={CenterActivity} />
                    <Route path='/ACHData' component={ACHData} />
                    <Route path='/TableDataMessage' component={TableDataMessage} />
                    <Route path='/Support' component={Support} />

                </Layout>
            </React.Fragment>


        );
    }
}