import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM, GetCachedCodeList} from '../AceCsiAccessHelper';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import './css/auth.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import TableDataByAuthNum from './TableDataByAuthNum'
import TableDataByAuthCenterAdj from './TableDataByAuthCenterAdj'
import TableDataByAuthCorpAdj from './TableDataByAuthCorpAdj'
import ErrorModal from '../Shared/ErrorModal';
import ReactDOM from 'react-dom';
import 'url-search-params-polyfill';
import config from '../../config';
import { CreateGetRequest } from '../Shared/ApiHelper';

function myFunction(x) {
    if (x.matches) { 
        //var collection = document.getElementsByClassName('authcenteradj');
        //if (collection.length > 0)
        //    alert(collection[0].rows[0].cells[0].innerHTML);
    } else {
      //  document.body.style.backgroundColor = "pink";

    }
}

var x = window.matchMedia("(max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)");
myFunction(x); // Call listener function at run time
x.addListener(myFunction); // Attach listener function on state changes

export class TransactionInquiryByAuth extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        var codes = GetCachedCodeList();

        this.state = {
            AuthenticationNumber: "",
            codes: [],
            validationError: "",
            clicked: false,
            loading: false,
            codeList: codes,
            returnResults: []
        };

        this.getAllAuthData = this.getAllAuthData.bind(this);
        this.getAuthPaymentDetail = this.getAuthPaymentDetail.bind(this);
        this.getAuthCenterAdjData = this.getAuthCenterAdjData.bind(this);
        this.getAuthCorpAdjData = this.getAuthCorpAdjData.bind(this);

    }

    componentDidMount() {
        var search = new URLSearchParams(window.location.search);
        let authnum = search.get("authnum");
        this.setState({
            AuthenticationNumber: authnum
        });
        if (authnum) {
            this.getAllAuthData();
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSubmit = (event, errors, values) => {
        
        this.setState({
            loading: true
        });
        this.setState({ errors, values });

        if (errors.length > 0) return;
        this.getAllAuthData();

    }

    getAllAuthData() {
        this.setState({
            clicked: false,
            loading: true
        });
        this.getAuthPaymentDetail();
        this.getAuthCenterAdjData();
        this.getAuthCorpAdjData();
    }
    getAuthPaymentDetail() {

        const uri = `${config.getApiRoot()}/transactions/online/authnum=${this.state.AuthenticationNumber}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ returnResults: response.body.transactions });
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }
    getAuthCenterAdjData() {

        const uri = `${config.getApiRoot()}/adjustments/centers/authnum=${this.state.AuthenticationNumber}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200 && response.body.transactions) {
                    this.setState({ returnResultsCenterAdj: response.body.transactions });
                }
                
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    getAuthCorpAdjData() {

        const uri = `${config.getApiRoot()}/adjustments/corporate/authnum=${this.state.AuthenticationNumber}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200 && response.body.transactions) {
                    this.setState({ returnResultsCorpAdj: response.body.transactions });
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
            <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Transaction Inquiry by Authentication Number</span>
                </div>
                <AvForm id="search_by_auth" onSubmit={this.handleSubmit}>
                    <AvField
                        id="AuthenticationNumber"
                        name="AuthenticationNumber"
                        label="Enter Authentication Number"
                        type="text"
                        value={this.state.AuthenticationNumber || ""}
                        onChange={this.handleChange}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Please enter an authentication number."
                            }
                        }}
                    />
                    <Button color="primary">GO</Button>
                </AvForm>

                {this.state.loading ? <div id="divloading">loading...</div> : null}
                {this.state.clicked ? <TableDataByAuthNum data={this.state.returnResults} /> : null}
                {this.state.clicked ? <TableDataByAuthCenterAdj data={this.state.returnResultsCenterAdj} /> : null}
                {this.state.clicked ? <TableDataByAuthCorpAdj data={this.state.returnResultsCorpAdj} /> : null}

            </div>
        );
    }
}