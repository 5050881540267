import React from "react";
import { NavLink, NavItem } from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";

export class LoginLogoutMenu extends React.Component {
    renderSwitch = () => {
        //sessionStorage.clear();
        //sessionStorage.setItem('UserProfileToken', JSON.stringify(new UserProfileToken("UserName", "ADM", "123-456-7890")));

        const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));

        if (token === null || token.UserName === "")
            return (
                <React.Fragment>
                    <NavItem>
                        <LinkContainer to="/Support">
                            <NavLink>Support</NavLink>
                        </LinkContainer>
                    </NavItem>

                    <NavItem>
                        <LinkContainer to="/Login">
                            <NavLink>Login</NavLink>
                        </LinkContainer>
                    </NavItem>

                </React.Fragment>
            );
        else
            return (
                <React.Fragment>
                    <li className="welcome">Welcome {token.UserName}</li>
                    <NavItem>
                        <LinkContainer to="/Support">
                            <NavLink>Support</NavLink>
                        </LinkContainer>
                    </NavItem>

                    <NavItem>
                        <LinkContainer to="/Logout">
                            <NavLink>Logout</NavLink>
                        </LinkContainer>
                    </NavItem>
                </React.Fragment>
            );
    };

    render() {
        return <React.Fragment>{this.renderSwitch()}</React.Fragment>;
    }
}
