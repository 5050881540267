import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, FormGroup, Label } from 'reactstrap';
import { withRouter } from 'react-router';
import ErrorModal from './Shared/ErrorModal';
import { UserProfileToken } from './Shared/UserProfileToken';
import Modal from 'react-responsive-modal';
import config from '../config';
import { CreatePostRequest } from './Shared/ApiHelper';

var divStyle = {
    color: 'red',
    display : 'none'
};

export class Login extends Component {
    constructor(props) {
        super(props);

        this.redirectToHomePage = this.redirectToHomePage.bind(this);
        this.redirectToHomePage();

        this.state = {
            username: '',
            password: '',
            role: 'NA',
            returnurl: '',
            Password: '',
            open: false,
            token:'',
            message: ''
        };

        this.resetPassword = this.resetPassword.bind(this);

        const token = JSON.parse(sessionStorage.getItem('UserProfileToken'));
        if (token === null) {
            sessionStorage.setItem(
                'UserProfileToken',
                JSON.stringify(new UserProfileToken())
            );
        }
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    }

    closeModal() {
        return;
    }

    validateConfirmPassword = (value, ctx, input, cb) => {

        if (document.getElementById('Password') !== null && document.getElementById('ConfirmPassword') !== null) {
            if (document.getElementById('ConfirmPassword').value !== '' &&
                document.getElementById('Password').value !== document.getElementById('ConfirmPassword').value) {
                cb('Password does not match.');
            }
        }

        cb(true);
    }

    redirectToHomePage = () => {
        const token = JSON.parse(sessionStorage.getItem('UserProfileToken'));
        if (token !== null) {
            let path = '';

            if (token.UserRole !== '') {
                path = `/ClientServicesInterface`;
            }

            if (path !== '') {
                this.props.history.push(path);
            }
        }
    };

    handleChange = event => {
        if (event.target.id === 'role') {
            this.setState({
                [event.target.id]: event.target.value.toUpperCase()
            });
        } else
            this.setState({
                [event.target.id]: event.target.value
            });
    };

    resetPassword() {
        let path = `/Account/ResetPassword/`;
        this.props.history.push(path);
    }
    
    handlePasswordSubmit = (event, errors, values) => {
        
        this.setState({ errors, values });

        if (errors.length > 0) {
            return;
        }

        const uri = `${config.getApiRoot()}/users/password/change`;

        const body = {
            Password: this.state.Password,
            Token: this.state.token
        };
 
        fetch(uri, CreatePostRequest(body))
            .then(res =>
                res.json().then(data => ({ status: res.status, body: data }))
            )
            .then(response => {
                if (response.status === 200) {
                    if (response.body.isSuccess)
                    this.redirectToHomePage();
                    else {
                        document.getElementById('pchangeerror').style.display = 'block';
                    }
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    handleSubmit = (event, errors, values) => {
        this.setState({ errors, values });

        if (errors.length > 0) {
            return;
        }

        const uri = `${config.getApiRoot()}/users/login`;
        
        const body = {
            username: this.state.username,
            password: this.state.password,
            role: this.state.role,
            returnurl: this.state.returnurl
        };

        
        fetch(uri, CreatePostRequest(body))
            .then(httpResponse => {
                if(httpResponse.ok) {
                    httpResponse.json()
                        .then(apiResponse => {
                            this.handleSuccess(apiResponse);
                        });
                }
                else {
                    httpResponse.text()
                        .then(responseText => {
                            if(responseText)
                                this.setState({ errorMessage: responseText });  
                            else { 
                                this.handleError('Server Error', `Error message: ${httpResponse.status} ${httpResponse.statusText}`);
                            }
                        })
                }
            })
            .catch(error => {
                this.handleError('Server Error', 'This issue has been logged into the database.');
            });
    };

    handleSuccess(apiResponse) {
        if (apiResponse.isSuccess === true) {
            const token = JSON.parse(sessionStorage.getItem('UserProfileToken'));
            token.UserName = apiResponse.userName;
            token.UserRole = apiResponse.role;
            sessionStorage.setItem('UserProfileToken', JSON.stringify(token));       

            this.redirectToHomePage();
        }

        
    } 
   
    handleError(title, body) {
        const errorModal = (<ErrorModal modalTitleText={ title } modalBodyText={ body } />);
        ReactDOM.render(null, document.getElementById('error-modal-container'));
        ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
    }

    render() {
        return (
            <div className="center">
                <table>
                    <tbody>
                        <tr>
                            <td >
                                <div id="error-modal-container" />
                                <div id="login-response-message" />
                                <div className="panel panel-default min-width-275">
                                    <div className="panel-heading rounded ">
                                        <h3 className="panel-title p-2">Login</h3>
                                    </div>
                                    <div className="panel-body rounded shadow p-3">
                                        <AvForm onSubmit={this.handleSubmit}>
                                            <FormGroup>
                                                <Label>User Name</Label>
                                                <AvField
                                                    id="username"
                                                    autoFocus
                                                    type="text"
                                                    name="username"
                                                    className="w-100 p-3"
                                                    value={this.state.username}
                                                    onChange={this.handleChange}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please enter username"
                                                        }
                                                    }}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Password</Label>
                                                <AvField
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    className="w-100 p-3"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Please enter password"
                                                        }
                                                    }}
                                                />
                                            </FormGroup>

                                            <Button block color="primary" type="submit">
                                                Login
                                            </Button>
                                        </AvForm>
                                        <Button color="link" onClick={this.resetPassword} formNoValidate className="center">
                                            Reset Password
                                        </Button>
                                        <div className="error center">{this.state.errorMessage}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Modal open={this.state.open} center onClose={this.closeModal}>
                    <h6>Please enter a new password: </h6>
                    <p>You must change your password on first login </p>
                    <p id='pchangeerror' style={divStyle} >There was an error changing your password. Please try again. </p>
                    <AvForm id="add-user-form" onSubmit={this.handlePasswordSubmit} onClose="return false;">
                        <AvField
                            id="Password"
                            name="Password"
                            label="Password"
                            type="password"
                            onChange={this.handleChange}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Please enter a password."
                                }
                            }}
                        />
                        <AvField
                            id="ConfirmPassword"
                            name="ConfirmPassword"
                            ref="ConfirmPassword"
                            label="Confirm Password"
                            type="password"
                            maxLength="15"
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Please enter confirmed password."
                                },
                                custom: this.validateConfirmPassword
                            }}
                        />
                        <Button color="primary">Submit</Button>
                    </AvForm>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Login);
