import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './css/authcorpadj.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

export class TableDataByAuthCorpAdj extends Component {
    render() {
        return (
            <div>
                <p className="Table-header">Corporate Adjustment Detail</p>
                <BootstrapTable striped data={this.props.data} className='authcorpadj' pagination>
                    <TableHeaderColumn dataField='account' isKey={true} >Account #</TableHeaderColumn>
                    <TableHeaderColumn dataField='code'>Code</TableHeaderColumn>
                    <TableHeaderColumn dataField='amount'>Amount</TableHeaderColumn>
                    <TableHeaderColumn dataField='center'>Center</TableHeaderColumn>
                    <TableHeaderColumn dataField='origDate'>Orig Date</TableHeaderColumn>
                    <TableHeaderColumn dataField='sentDate'>Date Sent</TableHeaderColumn>
                    <TableHeaderColumn dataField='batch'>Batch #</TableHeaderColumn>
                    <TableHeaderColumn dataField='auth'>Auth #</TableHeaderColumn>
                    <TableHeaderColumn dataField='name'>Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='phone'>Phone</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default TableDataByAuthCorpAdj;