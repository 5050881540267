import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import ReactDOM from 'react-dom';
import TableDataByMisAccount from './TableDataByMisAccount'
import config from '../../config';
import { CreateGetRequest } from '../Shared/ApiHelper';

export class TransactionInquiryByMisAcct extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);

        this.state = {
            codes: [],
            validationError: "",
            AccountNumber: "",
            clicked: false,
            loading: false,
            codeList: "",
            returnResults:[]
        };
        this.getAccountNumberData = this.getAccountNumberData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {

    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSubmit = (event, errors, values) => {
        
        this.setState({
            clicked: false,
            loading: true,
        });

        this.setState({ errors, values });

        if (errors.length > 0) return;
        this.getAccountNumberData();
    }
    getAccountNumberData(center) {

        const uri = `${config.getApiRoot()}/transactions/misapplied/accounts/${this.state.AccountNumber}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200 && response.body.transactions) {
                    this.setState({ returnResults: response.body.transactions })
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
        }

    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Transaction Inquiry by Misapplied Account Number</span>
                </div>
                <AvForm id="search_by_AccountNumber" onSubmit={this.handleSubmit}>
                    <AvField
                        id="AccountNumber"
                        name="AccountNumber"
                        label="Enter AccountNumber"
                        type="text"
                        value={this.state.AccountNumber || ""}
                        onChange={this.handleChange}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Please enter an account number."
                            }
                        }}
                    />
                    <Button color="primary">GO</Button>
                </AvForm>

                {this.state.loading ? <div id="divloading">loading...</div> : null}
    
                {this.state.clicked ? <TableDataByMisAccount data={this.state.returnResults} /> : null}
            </div>
        );
    }
}