import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  displayName = Layout.name

  render() {
    return (  
      <Grid fluid> 
        <Row>
          <Col sm={12}>
              <NavMenu />
          </Col>
        </Row>     
        <Row>
          <Col sm={12} className="my-3 p-3 bg-white rounded box-shadow">
            {this.props.children}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
          <footer className="footer fixed-bottom bg-dark">
            <div className="container">
              <span className="text-muted"><b>© Ace Cash Express Inc.</b></span>
            </div>
          </footer>
          </Col>
        </Row> 
      </Grid>
    );
  }
}
