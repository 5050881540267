import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";
import ReactDOM from 'react-dom';
import TableDataByDate from './TableDataByDate'
import config from '../../config';
import { CreateGetRequest } from '../Shared/ApiHelper';

export class TransactionInquiryByDate extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        let logininfo = JSON.parse(sessionStorage.getItem("logininfo"));
        this.state = {
            codes: [],
            codeList: "",
            dateProp: "",
            amtProp: "",
            selectedCode: "",
            returnResults: [],
            validationError: "",
            code: logininfo.code,
            index: logininfo.index

        };

        this.getDataByDate = this.getDataByDate.bind(this);
    }

    componentDidMount() {

        const uri = `${config.getApiRoot()}/utility/${this.state.index}/codes/${this.state.code}`;

        fetch(uri, CreateGetRequest())
            .then((response) => {
                return response.json();
            })
            .then(data => {
                const codeList = [{value: '', display: '« Select a Code »'}];
                const apiList = data.payCodes.map(code => { return { value: code, display: code } });
                this.setState({ codes: codeList.concat(apiList) });
            }).catch(error => {
                console.log(error);
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    handleSubmit = (event, errors, values) => { 
        event.preventDefault();
        if (this.state.amtProp && this.state.amtProp.trim()) {
            if (Number.isNaN(this.state.amtProp.trim())) {
                alert("Please enter a valid amount.");
                return;
            }
            if (isNaN(this.state.amtProp.trim())) {
                alert("Please enter a valid amount.");
                return;
            }
        } else {
            this.setState({
                amtProp: 0.00
            });
        }
        this.setState({
            clicked: false,
            loading: true,
        });
        this.setState({ errors, values });

        if (errors.length > 0) return;

        this.getDataByDate();
    }

    getDataByDate() {

        if (!this.state.selectedCode) {
            alert("Select a code first");
            return;
        }

        const uri = `${config.getApiRoot()}/activities/today/${this.state.selectedCode}/amount=${this.state.amtProp}?date=${this.state.dateProp}`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {                
                if (response.status === 200 && response.body.paymentActivities) {
                    this.setState({ returnResults: response.body.paymentActivities });
                }
                else
                {
                    this.setState( { returnResults: []});
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }
    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Transaction Inquiry by Date</span>
                </div>
                <AvForm id="search_by_date" onSubmit={this.handleSubmit}>
                    <AvField
                        name="dateProp" id="dateProp"
                        label="Enter Date:(mm/dd/yyyy)"
                        type="text"
                        onChange={this.handleChange}
                        validate={{
                            date: { format: 'MM/DD/YYYY' }, required: {
                                value: true
                            }
                        }} title="Use MM/DD/YYYY"
                    />
                    <AvField
                        name="amtProp" id="amtProp"
                        label="Enter Amount:(optional)"
                        type="text"
                        onChange={this.handleChange}
                    />

                    {/* <FormGroup>
                        <AvField type="select" id="codeList" name="codeList" label="Code" helpMessage="" value={this.state.selectedCode}
                            onChange={(e) => this.setState({ selectedCode: e.target.value, validationError: e.target.value === "" ? "You must select a code" : "" })}>
                            {this.state.codes.map((code) => <option key={code.value} value={code.value}>{code.display}</option>)}
                        </AvField>
                    </FormGroup> */}

                    <FormGroup>
                        <AvField 
                            type="select" 
                            id="codeList" 
                            name="codeList" 
                            label="Code" 
                            value={this.state.selectedCode}
                            onChange={
                                (e) => this.setState({ 
                                    selectedCode: e.target.value, 
                                    validationError: e.target.value === "" ? "You must select a code" : "" 
                                })
                            }>
                            {this.state.codes.map((code) => <option key={code.value} value={code.value}>{code.display}</option>)}
                        </AvField>
                    </FormGroup>
                    <br/>
                    <Button color="primary">GO</Button>
                </AvForm>
                <br/>
                {this.state.loading ? <div id="divloading">loading...</div> : null}
                {this.state.clicked ? <TableDataByDate code={this.state.selectedCode} data={this.state.returnResults} /> : null}
            </div>
        );
    }
}