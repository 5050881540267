import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Modal from "react-responsive-modal";
import { AuthModalData } from '../AceCsiAccessHelper';
import './css/date.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from "moment";

class ActiveFormatter extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            href: `/BillPay/TransactionInquiryByAccount?code=${props.code}&account=${this.props.account}`
        };
    }
    render() {
        return (
        <a href={this.state.href} onmouseover="this.style.color = 'blue'" onmouseout="this.style.color = '#669966'" > {this.props.account}</a >
    );

}
}

function activeFormatter(cell, row) {
    return (
    <ActiveFormatter code={row.utilCode} account={cell} />
);
}

class ActiveCenterFormatter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            href: "/BillPay/TodaysActivityByCenter?center=" + props.center+"&code="+this.props.code
        };
    }
    render() {
        return (
        <a href={this.state.href} onmouseover="this.style.color = 'blue'" onmouseout="this.style.color = '#669966'" > {this.props.center}</a >
    );
}
}

function activeCenterFormatter(cell, row) {
    return (<ActiveCenterFormatter center={cell} code={row.utilCode}/>);
}

class DateCellFormatter extends React.Component {
    render() {
        return (<span>{moment(this.props.date).format('MM/DD/YYYY')}</span>
    );
}
}

function dateCellFormatter(cell, row) {
    return (<DateCellFormatter date={cell}/>);
}

export class TableDataByDate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            clicked: false,
            loading: false
        };
    }
    onCloseModal = () => {
        this.setState({ open: false });
    };
    onOpenModal(cell, row, rowIndex) {
        var data = AuthModalData(row.authNum, row.amount);
        var authType = data.split(",")[0];

        let amtRange = data.split(",")[1];
        this.setState(
            {
                Date: row.date,
                Header: row.authNum,
                Center: row.center,
                Person: row.person,
                Amount: row.amount,
                TimeCST: 'N/A',
                AmountRange: amtRange,
                AuthType: authType,
                LastDigit: row.authNum.substring(4, 5)
            });
        this.setState({ open: true });
    }

    cellButton(cell, row, enumObject, rowIndex) {
        return (
        <button onClick={() => this.onOpenModal(cell, row, rowIndex)} class="link"><span>{cell}</span></button>)
}
    render() {
        return (
            <div>
                <BootstrapTable striped code={this.props.code} data={this.props.data} className='date' pagination>
                    <TableHeaderColumn dataField='date' dataFormat={dateCellFormatter}>Date</TableHeaderColumn>
                    <TableHeaderColumn dataField='center' dataFormat={activeCenterFormatter}>Center</TableHeaderColumn>
                    <TableHeaderColumn dataField='person'>Person</TableHeaderColumn>
                    <TableHeaderColumn dataField='account' isKey={true} dataFormat={activeFormatter}>Account</TableHeaderColumn>
                    <TableHeaderColumn dataField='amount'>Amount</TableHeaderColumn>
                    <TableHeaderColumn dataField='fee'>Fee</TableHeaderColumn>
                    <TableHeaderColumn dataField='phone'>Phone</TableHeaderColumn>
                    <TableHeaderColumn dataField='paidBy'>Paid By</TableHeaderColumn>
                    <TableHeaderColumn dataField='utilCode'>Code</TableHeaderColumn>
                    <TableHeaderColumn dataField='transNumber'>Trans Num</TableHeaderColumn>
                    <TableHeaderColumn dataField='authNum' dataFormat={this.cellButton.bind(this)}>Auth #</TableHeaderColumn>
                </BootstrapTable>

                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <h6>{this.state.Header} </h6>
                    <div class="container marginme">

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Store:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.Center}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Amount Range:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.AmountRange}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Date:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.Date}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Last Digit:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.LastDigit}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Amount:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.Amount}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Type:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.AuthType}</label>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}

export default TableDataByDate;