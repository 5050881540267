
import React from 'react';

export class AccessDenied extends React.Component {
    
    render() {     

        return (
            <div className="AccessDenied" />
        );
    }
}