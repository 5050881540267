import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './billpay.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import logo from './images/fp-db.jpg';


class ActiveFormatter extends React.Component {
    constructor(props) {
        super(props);
       this.state = {
           href: "/BillPay/TodaysActivity?code=" + this.props.code
       };
    }
    
    render() {
        return (
            <div>
            <img src={logo} alt="logo" width="16" height="18" />
                <a href={this.state.href} onmouseover="this.style.color = 'blue'" onmouseout="this.style.color = '#669966'" > <strong>{this.props.code}</strong></a>
            </div>
        );
    }
}

class AmountFormatter extends React.Component {
    render() {
        return (
            <div>
            {this.props.payments} <strong>Payments for a Total of </strong> <span>${this.props.amount}</span>
            </div>
        );
    }
}

function activeFormatter(cell, row) {
    return (<ActiveFormatter code={cell} />);
}

function amountFormatter(cell, row) {
    return (<AmountFormatter amount={cell} payments={row.numberOfPayments} />);
}

export class TableDataByPaymentsToday extends Component {
    render() {
        return (
            <div>
                <BootstrapTable striped data={this.props.data} bordered={false} tableContainerClass='tablec table-borderless ptoday' >
                    <TableHeaderColumn dataField='code' dataFormat={activeFormatter} isKey={true} width={'6%'}></TableHeaderColumn>
                    <TableHeaderColumn dataField='totalAmount' dataFormat={amountFormatter} width={'90%'}></TableHeaderColumn>
            </BootstrapTable>
            </div>
        );
    }
}

export default TableDataByPaymentsToday;