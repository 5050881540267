import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper'
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import ReactDOM from 'react-dom';
import TableDataByCenter from './TableDataByCenter'
import 'url-search-params-polyfill';
import config from '../../config';
import { CreateGetRequest } from '../Shared/ApiHelper';

export class TransactionInquiryByCenter extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        this.state = {
            codes: [],
            validationError: "",
            Center: "",
            clicked: false,
            loading: false,
            codeList: "",
            returnResults: []
        };

    }

    componentDidMount() {
        this.setState({
            clicked: false,
            loading: true
        });
        
        this.getAllCenterData();
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (event, errors, values) => {
        
        this.setState({
            clicked: false,
            loading: true,
        });
        
        this.setState({ errors, values });

        if (this.state.Center)
            this.getCenterData();
        else
            this.getAllCenterData();
    }

    getAllCenterData() {
        const uri = `${config.getApiRoot()}/centers/activeOnly`;

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ returnResults: response.body.activeCenters })
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    getCenterData(center) {
        let centerId = this.state.Center ? this.state.Center : center;
        const uri = `${config.getApiRoot()}/centers/${centerId}`;
        
        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    const centerArray = [response.body.center];
                    this.setState({ returnResults: centerArray })
                }
                this.setState({ clicked: true, loading: false });
            })
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Active Center List</span>
                </div>
                <AvForm id="search_by_Center" onSubmit={this.handleSubmit}>
                    <AvField
                        id="Center"
                        name="Center"
                        label="Find Center"
                        type="text"
                        value={this.state.Center}
                        onChange={this.handleChange}                
                    />
                    <Button color="primary">GO</Button>
                </AvForm>

                {this.state.loading ? <div id="divloading">loading...</div> : null}
                {this.state.clicked ? <TableDataByCenter data={this.state.returnResults} /> : null}
            </div>
        );
    }
}