import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import './css/center.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class CenterFormatter extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            href: `/BillPay/TodaysActivityByCenter?center=${this.props.center}&code=${this.props.code}`
        };
    }
    
    render() {
        return (<a href={this.state.href} onmouseover="this.style.color = 'blue'" onmouseout="this.style.color = '#669966'" >{this.props.center}</a>);

    }
}

function centerFormatter(cell, row) {
    let loginInfo = JSON.parse(sessionStorage.getItem("logininfo"));
    return (<CenterFormatter code={loginInfo.code} center={cell} />);
}

export class TableDataByCenter extends Component {
    render() { 
        return (
            <div>
                <br/>
                <BootstrapTable striped code='julio' data={this.props.data} className='centertbl' pagination>
                    <TableHeaderColumn dataField='centerNumber' dataFormat={centerFormatter} isKey={true} >Center</TableHeaderColumn>
                    <TableHeaderColumn dataField='address'>Address</TableHeaderColumn>
                    <TableHeaderColumn dataField='city'>City</TableHeaderColumn>
                    <TableHeaderColumn dataField='state'>State</TableHeaderColumn>
                    <TableHeaderColumn dataField='zip'>Zip</TableHeaderColumn>
                    <TableHeaderColumn dataField='phone'>Phone</TableHeaderColumn>
                    <TableHeaderColumn dataField='fax'>Fax</TableHeaderColumn>
                    <TableHeaderColumn dataField='dateOpened' dataFormat={dateFormatter}>Date Opened</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

export default TableDataByCenter;

function dateFormatter(cell) {
    if (!cell) {
        return "";
    }
    
    const date = new Date(cell);
    const formattedDate = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
}