import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";
import ReactDOM from 'react-dom';
import config from '../../config';
import { CreateGetRequest } from '../Shared/ApiHelper';
import { CreateGetDownloadRequest, DownloadFile } from '../Shared/ApiHelper';

export class ACHData extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        let logininfo = JSON.parse(sessionStorage.getItem("logininfo"));

        this.state = {
            codes: [],
            codeList: "",
            beginDateProp: "",
            endDateProp: "",
            selectedCode: "",
            selectedCodeList: "",
            returnResults: [],
            validationError: "",
            code: logininfo.code,
            index: logininfo.index,
            message: ''
        };

        this.getDataByDate = this.getDataByDate.bind(this);
    }
 
    componentDidMount() {

        const uri = `${config.getApiRoot()}/utility/${this.state.index}/codes/${this.state.code}`;

        fetch(uri, CreateGetRequest())
            .then((response) => {
                return response.json();
            })
            .then(data => {
                const codeList = [{value: '', display: '« Select a Code »'}];
                const apiList = data.payCodes.map(code => { return { value: code, display: code } });
                this.setState({ codes: codeList.concat(apiList) });
            }).catch(error => {
                console.log(error);
            })
        .catch(error => {
            console.error(error);
            const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
            ReactDOM.render(null, document.getElementById('error-modal-container'));
            ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
        });
    }

    handleMultipleChange = e => {
        var opts = [],
            opt;
        let val='';
        var len = e.target.options.length;
        for (var i = 0; i < len; i++) {
            opt = e.target.options[i];

            if (opt.selected) {
                opts.push(opt);
                val += opt.value+",";
            }
        }
        val = val.slice(0, -1);
        
        this.setState({
            selectedCodeList: val,
            validationError: e.target.value === "" ? "You must select a code" : ""
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = (event, errors, values) => {
        event.preventDefault();
        this.setState({
            clicked: false,
            loading: true,
            message: ''
        });

        this.setState({ errors, values });

        if (errors.length > 0) return;

        this.getDataByDate();
    };

    getDataByDate() {

        if (!this.state.selectedCode) {
            alert("Select at least one code first");
            return;
        }

        const uri = `${config.getApiRoot()}/downloads/ach/paycodes=${this.state.selectedCode}?fromDt=${this.state.beginDateProp}&toDt=${this.state.endDateProp}`;
        
        fetch(uri, CreateGetDownloadRequest())
            .then(response => response.blob())
            .then(blob => {

                if(blob && blob.size > 0) {
                    DownloadFile(blob, 'ACH_Data.xlsx');
                }
                else {
                    alert('unable to download file')
                }

                this.setState({ clicked: true, loading: false });
            })    
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    };
    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div className="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Download ACH Data</span>
                </div>
                <AvForm id="search_by_date" onSubmit={this.handleSubmit}>
                    <AvField
                        name="beginDateProp" id="beginDateProp"
                        label="Enter Begin Date:(mm/dd/yyyy)"
                        type="text"
                        onChange={this.handleChange}
                        validate={{
                            date: { format: 'MM/DD/YYYY' }, required: {
                                value: true
                            }
                        }} title="Use MM/DD/YYYY"
                    />
                    <AvField
                        name="endDateProp" id="endDateProp"
                        label="Enter Ending Date:(mm/dd/yyyy)"
                        type="text"
                        onChange={this.handleChange}
                        validate={{
                            date: { format: 'MM/DD/YYYY' }, required: {
                                value: true
                            }
                        }} title="Use MM/DD/YYYY"
                    />
                    <FormGroup>
                        <AvField 
                            type="select" 
                            id="codeList" 
                            name="codeList" 
                            label="Code" 
                            value={this.state.selectedCode}
                            onChange={
                                (e) => this.setState({ 
                                    selectedCode: e.target.value, 
                                    validationError: e.target.value === "" ? "You must select a code" : "" 
                                })
                            }>
                            {this.state.codes.map((code) => <option key={code.value} value={code.value}>{code.display}</option>)}
                        </AvField>
                    </FormGroup>

                    <Button color="primary">GO</Button>

                    <br/><br/>
                    <h5>{this.state.message}</h5>
                </AvForm>
                {this.state.loading ? <div id="divloading">loading...</div> : null}

            </div>
        );
    }
}