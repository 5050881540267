import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button } from 'reactstrap';
import ErrorModal from '../Shared/ErrorModal'
import ErrorAlert from '../Shared/ErrorAlert'
import SuccessAlert from '../Shared/SuccessAlert'
import { CreateGetRequest, CreatePostRequest } from '../Shared/ApiHelper';
import config from '../../config';

export class ChangePassword extends Component {

    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');

        this.state = {            
            UserName: "",
            Password: "",
            Token: token,
            IsTokenValidationComplete: false,
            IsTokenValid: false
        };

        this.resetPassword = this.resetPassword.bind(this);
    }
    
    componentDidMount () {
        this.renderForm = this.renderForm.bind(this);
        this.validateToken = this.validateToken.bind(this);        
        this.validateToken();
     }

     resetPassword() {
        let path = `/Account/ResetPassword/`;
        this.props.history.push(path);
    }

    validateToken = () => {
        
        const uri = `${config.getApiRoot()}/users/${this.state.Token}`;        
        
        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {                    
                    if (response.body.isSuccess === true) {                        
                        this.setState({ IsTokenValid: true, IsTokenValidationComplete: true, UserName: response.body.userName });
                    }
                    else {
                        this.setState({ IsTokenValidationComplete: true, IsTokenValid: false });
                    }
                }                
                else {                    
                    const titleText = '';
                    const bodyText = response.body;
                    const errorModal = (<ErrorModal modalTitleText={titleText} modalBodyText={bodyText}></ErrorModal>);
                    ReactDOM.render(null, document.getElementById('error-modal-container'));
                    ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                }
            })
            .catch(error => {                 
                if(document.getElementById('error-modal-container') != null)
                {
                    const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                    ReactDOM.render(null, document.getElementById('error-modal-container'));
                    ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                }
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (event, errors, values) => {
        event.preventDefault();
        this.setState({ errors, values });

        if (errors.length > 0) return;

        var body = {
            token: this.state.Token,
            password: this.state.Password
        };

        const uri = `${config.getApiRoot()}/users/password/change`;

        fetch(uri, CreatePostRequest(body))
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) { 
                    if (response.body.isSuccess === true) {
                        const successAlert = (<SuccessAlert messageText="The password has been changed successfully."></SuccessAlert>);
                        ReactDOM.render(successAlert, document.getElementById('changepwd-response-message'));
                        document.getElementById("changepwd-form").reset(); //reset form
                    }
                    else {
                        const errorAlert = (<ErrorAlert messageText={response.body.errorDescription}></ErrorAlert>);
                        ReactDOM.render(errorAlert, document.getElementById('changepwd-response-message'));
                    }
                }
                else { //with BadRequest : response.status === 400
                    const titleText = "Bad Request: " + response.status;
                    const bodyText = response.body + " This issue has been logged into database with Log ID:";
                    const errorModal = (<ErrorModal modalTitleText={titleText} modalBodyText={bodyText}></ErrorModal>);
                    ReactDOM.render(null, document.getElementById('error-modal-container'));
                    ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                }
            })
            .catch(error => { //with Ex : response.status === 500
                console.error(error);

                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
            });
    }

    renderForm() {
        return (
            <div>
                <div id="error-modal-container"></div>
                <div id="changepwd-response-message"></div>

                <div className="panel panel-default width-40">  
                    <h2>Change Password</h2>
                    <div className="panel-body">
                        <AvForm id="changepwd-form" onSubmit={this.handleSubmit}>
                            <p>Welcome <b>{this.state.UserName}</b>, please enter a new password.</p>
                            <AvField id="token" name="token" type="hidden" value={this.state.Token} />
                            <AvField id="Password" name="Password" label="Password" type="password" className="w-50 p-3" onChange={this.handleChange} validate={{
                                required: { value: true, errorMessage: 'Please enter a password.' }
                            }} />
                            <AvField id="ConfirmPassword" name="ConfirmPassword" label="Confirm Password" className="w-50 p-3" type="password" validate={{
                                required: { value: true, errorMessage: 'Please enter confirmed password.' },
                                match: { value: 'Password', errorMessage: 'Password does not match.' }
                            }} />
                            <Button color="primary">Submit</Button>
                        </AvForm>
                    </div>

                </div>
            </div>
        );
    }

    renderTokenInvalid() {
            return (
                <div>
                    <ErrorAlert messageText='The Token is invalid or expired. Please retry (or) reset password again.'></ErrorAlert>                                   
                </div>
            );
    }

    renderValidateToken() {
        return (
            <div>
                <p><em>User Validated. Please click continue to change password.</em></p>
                <Button color="primary" onClick={() => window.location.reload()}>Continue</Button> &nbsp;&nbsp;                    
            </div>
        );
    }

    render() {
        let contents = !this.state.IsTokenValidationComplete
            ? this.renderValidateToken()
            : (this.state.IsTokenValid ? this.renderForm() : this.renderTokenInvalid());

        return (
            <div>
                {contents}
            </div>
        );
    }

}
