import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Modal from "react-responsive-modal";
import './css/account.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { AuthModalData } from '../AceCsiAccessHelper';

export class TableDataByAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            clicked: false,
            loading: false
        };
    }
    onCloseModal = () => {
        this.setState({ open: false });
    };

    onOpenModal(cell, row, rowIndex) {
        var data = AuthModalData(row.auth, row.amount);
        var authType = data.split(",")[0];

        let amtRange = data.split(",")[1];
        this.setState(
            {
                Header: row.auth,
                Center: row.center,
                Person: row.name,
                Amount: row.amount,
                TimeCST: row.timeCST,
                TransDate: row.transDT,
                AmountRange: amtRange,
                AuthType: authType,
                LastDigit: row.auth.substring(4, 5)
            });
        this.setState({ open: true });
    };
    cellButton(cell, row, enumObject, rowIndex) {
        return (
        <button onClick={() => this.onOpenModal(cell, row, rowIndex)} class="link"><span>{cell}</span></button>)
}
    render() {
        return (
            <div>
                <BootstrapTable striped data={this.props.data} className="account" pagination>
            <TableHeaderColumn dataField='account' isKey={true}>Account #</TableHeaderColumn>
            <TableHeaderColumn dataField='code'>Code</TableHeaderColumn>
            <TableHeaderColumn dataField='amount'>Amount</TableHeaderColumn>
            <TableHeaderColumn dataField='center'>Center</TableHeaderColumn>
            <TableHeaderColumn dataField='transDT'>Trans Date and Time</TableHeaderColumn>            
            <TableHeaderColumn dataField='dateSent'>Date Sent</TableHeaderColumn>
            <TableHeaderColumn dataField='batch'>Batch #</TableHeaderColumn>
                    <TableHeaderColumn dataField='auth' dataFormat={this.cellButton.bind(this)}>Auth #</TableHeaderColumn>
            <TableHeaderColumn dataField='name'>Name</TableHeaderColumn>
            <TableHeaderColumn dataField='phone'>Phone</TableHeaderColumn>

                </BootstrapTable>

                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <h6>{this.state.Header} </h6>
                    <div class="container marginme">
                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Date and Time:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.TransDate}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Store:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.Center}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Amount Range:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.AmountRange}</label>
                            </div>
                        </div>
                        

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Last Digit:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.LastDigit}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Amount:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.Amount}</label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xs-3 col-form-label mr-2">Type:</label>
                            <div class="col-xs-9">
                                <label class="form-control">{this.state.AuthType}</label>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default TableDataByAccount;