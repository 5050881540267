import 'bootstrap/dist/css/bootstrap.css';
import './bootstrap-theme.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie9';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { UserProfileToken } from './components/Shared/UserProfileToken';

const baseUrl = 'BillPay';
const rootElement = document.getElementById('root');

//store empty token in session
const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));
if(token === null) {
  sessionStorage.setItem('UserProfileToken', JSON.stringify(new UserProfileToken()));
}

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
