import config from "../../config";

export class UserProfileToken {

    UserName = ""; 
    UserRole = "";  
    UserToken = "";  
    UserTokenType = ""; 
    UserTokenValidUpto = new Date(-8640000000000000);

    constructor() {
        SetUserTokenIfExpired();      
    }
}

export function GetUserToken() {
    const userProfileToken = JSON.parse(sessionStorage.getItem("UserProfileToken"));
    SetUserTokenIfExpired();
    return userProfileToken.UserTokenType + " " + userProfileToken.UserToken;
}


//---------
export function SetUserTokenIfExpired() {
    if(IsUserTokenExpired() === true){
        var request = {
            userId: config.userProfileTokenCredentials.userId,
            callerId: config.userProfileTokenCredentials.callerId,
            password: config.userProfileTokenCredentials.password
        };
        const uri = `${config.getApiRoot()}/authorize/`;
        fetch(uri, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
            })
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
                if (response.status === 200) {
                    const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));
                    token.UserToken = response.body.token;
                    token.UserTokenType = response.body.type;
                    token.UserTokenValidUpto = SetUserTokenValidUpto(response.body.expiresIn);
                    sessionStorage.setItem('UserProfileToken', JSON.stringify(token));
                }
            })
            .catch(error => {
                console.error(error);
            });
              
    }
}    

export function IsUserTokenExpired() {
    const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));
    if(token == null) return true;

    if(new Date() < new Date(token.UserTokenValidUpto) ){        
        return false;
    }    
    return true;
} 

export function SetUserTokenValidUpto(uTokenExpiresIn) {
    if(uTokenExpiresIn.neverExpires) {
        return new Date(8640000000000000);
    }
    else {
        var expiresOn = new Date();
        expiresOn.setHours ( expiresOn.getHours() + uTokenExpiresIn.hours );
        expiresOn.setMinutes ( expiresOn.getMinutes() + uTokenExpiresIn.minutes );
        expiresOn.setSeconds ( expiresOn.getSeconds() + uTokenExpiresIn.seconds );
        return expiresOn;
    }
}