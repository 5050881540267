import React from 'react';
import { Alert } from 'reactstrap';

class SuccessAlert extends React.Component {
    render() {      

        return (
            <div>
                <Alert color="success" fade={false}>
                    {this.props.messageText}
                </Alert>
            </div>
        );
    }
}

export default SuccessAlert;