import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import ReactDOM from 'react-dom';
import TableDataByDate from './TableDataByDate'
import config from '../../config';
import 'url-search-params-polyfill';
import { CreateGetRequest } from '../Shared/ApiHelper';

export class TodaysActivityByCenter extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        this.state = {
            codes: [],
            validationError: "",
            Center: "",
            clicked: false,
            loading: false,
            codeList: "",
            returnResults: []
        };

    }

    componentDidMount() {
        var search = new URLSearchParams(window.location.search);
        let center = search.get('center');
        let code = search.get('code');
        let payCode = search.get('payCode');
        document.getElementById("pgtitle").innerHTML = `Today's Activity for Center ${center} and code ${code ? code : payCode}`;
        
        this.setState({
            Center: center,
            Code: code,
            PayCode: payCode
        });
        
        if (center && (code || payCode)) {
            this.getCenterData(center, code, payCode);
        }
        else {
            alert('Invalid query string received');
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSubmit = (event, errors, values) => {
        event.preventDefault();
        
        this.setState({
            clicked: false,
            loading: true,
        });
        
        this.setState({ errors, values });

        if (errors.length > 0) return;
        
        this.getCenterData();

    }
    getCenterData(center, code, payCode) {

        let uri = '';

        this.setState({
            clicked: false,
            loading: true
        });
        
        if(code) {
            const loginInfo = JSON.parse(sessionStorage.getItem("logininfo"));
            uri = `${config.getApiRoot()}/activities/today/${code}/centers/${center}?index=${loginInfo.index}`;
        }
        
        if(payCode) {
            uri = `${config.getApiRoot()}/activities/today/${payCode}/centers/${center}`;
        }

        fetch(uri, CreateGetRequest())
            .then(res => res.json().then(data => ({ status: res.status, body: data })))
            .then((response) => {
               
                if (response.status === 200) {
                    this.setState({ returnResults: response.body.paymentActivities })
                }
               
                this.setState({
                    clicked: true,
                    loading: false
                });
            })
            .catch(error => {
                console.error(error);

                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
            });

    }


    render() {
        return (
            <div class="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div class="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div class="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div class="clear"></div>
                <div class="padBackBtn">
                    <button class="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span class="pgtitle" id="pgtitle">  Transaction Inquiry by Center</span>
                </div>

                {this.state.loading ? <div id="divloading">loading...</div> : null}

                {this.state.clicked ? <TableDataByDate julio={this.state.Code} data={this.state.returnResults} /> : null}
            </div>
        );
    }
}

