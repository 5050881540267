import React from 'react';
import AceCsiBase from '../AceCsiBase';
import { OnlyAccessToBPM } from '../AceCsiAccessHelper';
import ErrorModal from '../Shared/ErrorModal';
import 'bootstrap/dist/css/bootstrap.css';
import './billpay.css';
import hdrlogo from './images/fp-top1.jpg';
import hdrlogoBkg from './images/fp-top1bg.jpg';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "reactstrap";
import ReactDOM from 'react-dom';
import config from '../../config';
import { CreateGetDownloadRequest, DownloadFile } from '../Shared/ApiHelper';

export class CenterActivity extends AceCsiBase {
    constructor(props) {
        super(props);
        OnlyAccessToBPM(this.props.history);
        
        this.state = {
            validationError: "",
            dateProp:"",
            Center: "",
            code: JSON.parse(sessionStorage.getItem("logininfo")).code,
            clicked: false,
            loading: false,
            returnResults: [],
            message: ''
        };

        this.getCenterData = this.getCenterData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
      
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSubmit = (event, errors, values) => {
        
        this.setState({
            clicked: false,
            loading: true,
            message: ''
        });
        this.setState({ errors, values });

        if (errors.length > 0) return;
        this.getCenterData();

    }
    getCenterData() {
        const uri = `${config.getApiRoot()}/downloads/centers/${this.state.Center}/activities/${'CSI'}/codes/${this.state.code}`;

        fetch(uri, CreateGetDownloadRequest())
            .then(response => response.blob())
            .then(blob => {
                if(blob && blob.size > 0) {
                    DownloadFile(blob, 'CenterActivity.xlsx');
                }
                else {
                    alert('Unable to download file')
                }

                this.setState({ clicked: true, loading: false });
            })    
            .catch(error => {
                console.error(error);
                const errorModal = (<ErrorModal modalTitleText="Server Error" modalBodyText="This issue has been logged into database."></ErrorModal>);
                ReactDOM.render(null, document.getElementById('error-modal-container'));
                ReactDOM.render(errorModal, document.getElementById('error-modal-container'));
                this.setState({ clicked: true, loading: false });
            });
    }

    render() {
        return (
            <div className="lowest">
                <div id="error-modal-container"></div>
                <a href="/BillPay">
                    <div className="mnlogo left"> <img src={hdrlogo} alt="hdrlogo" /></div>
                    <div v="bkgimg left" style={{ backgroundImage: "url(" + hdrlogoBkg + ")" }}></div>
                </a>
                <div className="clear"></div>
                <div className="padBackBtn">
                    <button className="buttonBg" type="submit" onClick={() => { this.props.history.goBack() }}>Go Back</button> <span className="pgtitle">  Download Activity by Center</span>
                </div>
                <AvForm id="search_by_Center" onSubmit={this.handleSubmit}>
                    <AvField
                        id="Center"
                        name="Center"
                        label="Enter Center"
                        type="text"
                        value={this.state.Center}
                        onChange={this.handleChange}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: "Please enter a center."
                            }
                        }}
                    />                    
                    <Button color="primary">GO</Button>
                    <br/><br/>
                    <h5>{this.state.message}</h5>
                </AvForm>

                {this.state.loading ? <div id="divloading">loading...</div> : null}

            </div>
        );
    }
}