export function OnlyAccessToBPM(history) {
    //check session token
    const token = JSON.parse(sessionStorage.getItem("UserProfileToken"));
    if (token === undefined || token === null || token === 'undefined') {
        {
            //redirect to login page
            let path = `/Login`;
            history.push(path);
        }
    }
}

export function AuthModalData(authnum, amount) {
    var authType = authnum.substring(10, 11);
    if (authType === "1")
        authType = "Cash";
    else if (authType === "2")
        authType = "Check";
    else
        authType = "Debit Card";

    let amtRange = "less than $100";
    if (amount >= 100 && amount <= 199) {
        amtRange = "between $100 and $199";
    } else if (amount >= 200 && amount <= 299) {
        amtRange = "between $200 and $299";
    } else if (amount > 300 && amount <= 399) {
        amtRange = "between $300 and $399";
    } else if (amount > 400 && amount <= 499) {
        amtRange = "between $400 and $499";
    } else if (amount > 500 && amount <= 599) {
        amtRange = "between $500 and $599";
    } else if (amount > 600 && amount <= 699) {
        amtRange = "between $600 and $699";
    } else if (amount > 700 && amount <= 799) {
        amtRange = "between $700 and $799";
    } else if (amount > 800 && amount <= 899) {
        amtRange = "between $800 and $899";
    } else if (amount > 900) {
        amtRange = "greater than $900";
    }

    return authType + "," + amtRange;
}

export function GetCachedLoginInfo() {
    let logjson = sessionStorage.getItem("logininfo");
    let loginfo='';
    if (logjson) {
        loginfo = JSON.parse(logjson);
    }
    return loginfo;
}

export function GetCachedCodeList() {
    let logcodes = sessionStorage.getItem("codes");

    let codes, codeStr = '';
    if (logcodes) {
        codes = JSON.parse(logcodes);

        for (let i = 0; i < codes.payCodes.length; i++) {
            codeStr += codes.payCodes[i] +",";
        }
        codeStr = codeStr.slice(0, -1);
    }

    return codeStr;
}

