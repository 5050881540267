import { GetUserToken } from '../Shared/UserProfileToken';

export function CreatePostRequest(data) {
    
    const request = {
        method: 'POST',
        body: JSON.stringify(data), 
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: GetUserToken()
        } 
    }

    return request;    
}

export function CreateGetRequest() {
    
    const request = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: GetUserToken()
        }
    }

    return request;
}

export function CreateGetDownloadRequest() {
    
    const request = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `attachment;`, 
            Accept: 'application/json',
            Authorization: GetUserToken()
        }
    }

    return request;
}

export function DownloadFile(blob, fileName) {
    
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);  // Terrible browsers (IE)
    }
    else {
        const url = window.URL.createObjectURL(blob); // Normal browsers
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
